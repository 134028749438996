<template>
  <div>
    <portal to="page-top-title">Companies</portal>
    <portal to="page-top-right">
      <!--<btn :route="{ name: 'companies-new' }" icon="fa-plus" text>Add New</btn>-->
    </portal>

    <box-table title="List" url="/companies" data-prop="companies" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="company in companies" :key="`companies-tr-${company.id}`" :value="company">
          <td>{{ company.id }}</td>
          <td>{{ company.name }}</td>
          <td class="text-center">
            <badge-active :value="company.active"/>
          </td>
          <td class="text-right">{{ company.created_at }}</td>
          <td class="text-right">{{ company.updated_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'companies-single', params: { id: company.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Btn from '@/views/components/simple/Btn'
import company from '@/modules/strubs/company'

export default {
  name: 'CompaniesList',
  metaInfo: {
    title: 'Companies'
  },
  components: {
    BoxTable,
    BadgeActive,
    Btn
  },
  data () {
    return {
      companies: [company]
    }
  }
}
</script>
